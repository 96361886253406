import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

function PrivacyPage () {
  useEffect(() => {
    const event = new Event('view-loaded');
    window.dispatchEvent(event);
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <h1>this is the PrivacyPage page</h1>
        </div>
      </div>
    </div>

  );
}

export default PrivacyPage;