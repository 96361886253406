import React from "react";

function NavbarGeneral () {
  return (
    <header id="header" className="sticky-header">
      <div className="max-container">

        <strong className="logo">
          <a href="#">
            <span className="img">
              <img src="/images/logo.svg" alt="etrune logo" />
            </span>
          </a>
        </strong>

        <div className="align-right">
          <nav className="navbar navbar-default">
            <div className="container-fluid">

              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </div>

              <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul className="nav navbar-nav">
                  <li>
                    <a href="/">home</a>
                  </li>
                  <li className="dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Company <span className="caret"></span></a>
                    <ul className="dropdown-menu">
                      <li><a href="/company/about">About us</a></li>
                      <li><a href="/company/work">Work with us</a></li>
                    </ul>
                  </li>
                  <li><a href="/company/contact">Contact</a></li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default NavbarGeneral;