import React from "react";
import { Outlet } from "react-router-dom";
import NavbarGeneral from "../components/navbar/NavbarGeneral";
import FooterGeneral from "../components/footer/FooterGeneral";

const LayoutGeneral = () => {
  return (
    <>
      <div id="wrapper">
        <NavbarGeneral />

        <main id="main">
          {/* Start Content */}
          <Outlet />
          {/* End Content */}
        </main>

        <FooterGeneral />
        <span id="back-top" className="fa fa-arrow-up"></span>
      </div>
    </>
  );
};

export default LayoutGeneral;