import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

function HomeBlue1Page () {
  useEffect(() => {
    const eventHomeLoaded = new Event('view-home-loaded');
    const pageLoaded = new Event('view-loaded');
    window.dispatchEvent(eventHomeLoaded);
    window.dispatchEvent(pageLoaded);
  }, []);

  return (
    <>
      {/* Main slider */}
      <section className="visual">
        <div className="max-container">
          <section className="slideshow main-slider" id="main-slider">
            <article className="slide">
              <div className="caption">
                <div className="text">
                  <span className="txt">WE LOVE TO CREATE</span>
                  <h1><span className="icon">#</span><span className="light">PASSION</span></h1>
                </div>
              </div>
              <div className="image">
                <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/hero-1.jpg?alt=media&token=dab7ef3f-0da6-4d79-802d-db3f42c39300" alt="image description" />
              </div>
              <span className="box"></span>
            </article>
            <article className="slide">
              <div className="caption">
                <div className="text">
                  <span className="txt">WE LOVE TO CREATE</span>
                  <h1><span className="icon">#</span><span className="light">UNIQUE</span></h1>
                </div>
              </div>
              <div className="image">
                <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/hero-2.jpg?alt=media&token=47738fa9-4b85-42c4-87da-9e71467f7f17" alt="image description" />
              </div>
              <span className="box"></span>
            </article>
            <article className="slide">
              <div className="caption">
                <div className="text">
                  <span className="txt">WE LOVE TO CREATE</span>
                  <h1><span className="icon">#</span><span className="light">ART</span></h1>
                </div>
              </div>
              <div className="image">
                <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/hero-3.jpg?alt=media&token=d2debd05-d27f-4a71-b101-969545d6727a" alt="image description" />
              </div>
              <span className="box"></span>
            </article>
          </section>
        </div>
      </section>

      <div className="max-container">
        <section className="info-holder">
          {/* 01) About */}
          <article className="info">
            <div className="img-frame wow fadeInLeft" data-wow-delay="0.6s">
              <div className="text">
                <span className="num">01</span>
                <h2 className="tild">ABOUT US</h2>
              </div>
              <div className="image wow fadeInRight" data-wow-delay="0.6s"><img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/about-us.jpg?alt=media&token=92d0cbfd-514c-457e-90f1-e3acabae6735" alt="image description" /></div>
            </div>
            <div className="txt-frame wow fadeInUp" data-wow-delay="0.6s">
              <h3 className="mb-5 pb-2">About Us</h3>
              <p className="mb-4">At ÊTRUNE we seek that all couples who believe in marriage can acquire their perfect engagement ring in an easy, accessible and elegant way through an interactive process in which he personifies this object.</p>
              <p>We are a platform which allows you to have access to multiple tools, including the collaborative economy, assistance from experts in the field, artistic customizations and technological support throughout the process until you can fulfill your dream and find the engagement ring that represents pure love.</p>
            </div>
          </article>

          {/* 03) Our Works */}
          <article className="info work">
            <div className="img-frame">
              <div className="img-area" id="masonry-container">
                <div className="item work-i work-w wow fadeInUp filter-photography" data-wow-delay="0.6s">
                  <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/gallery-01.jpg?alt=media&token=d87c9d1f-4108-44c9-b979-fa886ef5a89a" alt="image description" />
                  <div className="caption">
                    <div className="c1">
                      <div className="c2">
                        <strong className="title">Amber Bottle</strong>
                        <p>Branding Identity</p>
                        <ul className="icons">
                          <li><a href="images/img1.jpg" className="lightbox"><i className="icon-resize-full-alt"></i> <span>resize</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item work-m wow fadeInUp filter-makingof filter-gemstones filter-jewelry" data-wow-delay="0.6s">
                  <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/gallery-02.jpg?alt=media&token=3a81fad8-b896-47be-bd1b-9c4748ecc34e" alt="image description" />
                  <div className="caption">
                    <div className="c1">
                      <div className="c2">
                        <strong className="title">Amber Bottle</strong>
                        <p>Branding Identity</p>
                        <ul className="icons">
                          <li><a href="images/img1.jpg" className="lightbox"><i className="icon-resize-full-alt"></i> <span>resize</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item work-i work-b wow fadeInUp filter-photography filter-engagementring" data-wow-delay="0.6s">
                  <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/gallery-04.jpg?alt=media&token=9a917e0a-125a-4be4-b3c7-1e6c9eed0e1e" alt="image description" />
                  <div className="caption">
                    <div className="c1">
                      <div className="c2">
                        <strong className="title">Amber Bottle</strong>
                        <p>Branding Identity</p>
                        <ul className="icons">
                          <li><a href="images/img1.jpg" className="lightbox"><i className="icon-resize-full-alt"></i> <span>resize</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item add work-w wow fadeInUp filter-gemstones filter-jewelry filter-engagementring" data-wow-delay="0.6s">
                  <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/gallery-06.jpg?alt=media&token=59aad75c-c761-41dd-b9a6-43801002d272" alt="image description" />
                  <div className="caption">
                    <div className="c1">
                      <div className="c2">
                        <strong className="title">Amber Bottle</strong>
                        <p>Branding Identity</p>
                        <ul className="icons">
                          <li><a href="images/img1.jpg" className="lightbox"><i className="icon-resize-full-alt"></i> <span>resize</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item work-i work-b wow fadeInUp filter-makingof" data-wow-delay="0.6s">
                  <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/gallery-03.jpg?alt=media&token=ffa6436c-9615-4824-9ba5-4867628a691e" alt="image description" />
                  <div className="caption">
                    <div className="c1">
                      <div className="c2">
                        <strong className="title">Amber Bottle</strong>
                        <p>Branding Identity</p>
                        <ul className="icons">
                          <li><a href="images/img1.jpg" className="lightbox"><i className="icon-resize-full-alt"></i> <span>resize</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item add wow fadeInUp filter-gemstones" data-wow-delay="0.6s">
                  <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/gallery-05.jpg?alt=media&token=5526d259-09f4-41d9-b69f-9870c42beb3a" alt="image description" />
                  <div className="caption">
                    <div className="c1">
                      <div className="c2">
                        <strong className="title">Amber Bottle</strong>
                        <p>Branding Identity</p>
                        <ul className="icons">
                          <li><a href="images/img1.jpg" className="lightbox"><i className="icon-resize-full-alt"></i> <span>resize</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="txt-frame wow fadeInLeft" data-wow-delay="0.6s">
              <h2 className="tild">OUR GALLERY</h2>
              <span className="num">02</span>
              <ul className="filter">
                <li className="active"><a href="#">All</a></li>
                <li><a href="#" data-filter=".filter-photography">Photography</a></li>
                <li><a href="#" data-filter=".filter-makingof">Making of</a></li>
                <li><a href="#" data-filter=".filter-gemstones">Gemstones</a></li>
                <li><a href="#" data-filter=".filter-jewelry">Jewelry</a></li>
                <li><a href="#" data-filter=".filter-engagementring">Engagement ring</a></li>
              </ul>
              <a href="https://www.instagram.com/etrune.jewels/" target="_blank" className="more">view all</a>
            </div>
          </article>

          {/* 05) Testimonials */}
          <article className="info testimonial">
            <div className="txt-frame wow fadeInLeft" data-wow-delay="0.6s">
              <h2 className="tild">TESTIMONIALS</h2>
              <span className="num">03</span>
            </div>
            <div className="img-frame wow fadeInUp" data-wow-delay="0.6s">
              <div className="slider">
                <div className="mask">
                  <div className="slide">
                    <blockquote>
                      <q>“I can get my engagement ring in less than 4 months. I like how ÊTRUNE makes it easy to design and sell engagement rings online. The quality of the ring is great and I'm glad that I found it here!”</q>
                      <cite>
                        <span className="heading4">Kawhi Leonard</span>
                        <span className="designation">Creative Director</span>
                      </cite>
                    </blockquote>
                    <div className="images">
                      <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/testimonials-01.jpg?alt=media&token=06893c2a-84de-464e-a8a2-57e9c41a58d2" alt="image description" />
                    </div>
                  </div>
                  <div className="slide">
                    <blockquote>
                      <q>“I was really impressed with the quality and the design of the ÊTRUNE ring we chose.”</q>
                      <cite>
                        <span className="heading4">Umair Rafi</span>
                        <span className="designation">Creative Developer</span>
                      </cite>
                    </blockquote>
                    <div className="images">
                      <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/testimonials-04.jpg?alt=media&token=dd02b83e-524a-48ac-a405-ffccf5ed2a50" alt="image description" />
                    </div>
                  </div>
                  <div className="slide">
                    <blockquote>
                      <q>“The personalized and interactive process allows us to select the better engagement ring for our needs, our dreams and aspirations create the perfect wedding ring.”</q>
                      <cite>
                        <span className="heading4">Leo Alpha</span>
                        <span className="designation">Ui/UX Director</span>
                      </cite>
                    </blockquote>
                    <div className="images">
                      <img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/testimonials-03.jpg?alt=media&token=4c47267e-b9f3-43cd-8679-eccc4942d25b" alt="image description" />
                    </div>
                  </div>
                </div>
                <div className="pagination">
                  <ul className="mask-inner">
                    <li>
                      <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/testimonials-01.jpg?alt=media&token=06893c2a-84de-464e-a8a2-57e9c41a58d2" alt="image description" /></a>
                    </li>
                    <li>
                      <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/testimonials-04.jpg?alt=media&token=dd02b83e-524a-48ac-a405-ffccf5ed2a50" alt="image description" /></a>
                    </li>
                    <li className="active">
                      <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/etrune-prod.appspot.com/o/testimonials-03.jpg?alt=media&token=4c47267e-b9f3-43cd-8679-eccc4942d25b" alt="image description" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </article>
        </section>

      </div>
    </>
  );
}

export default HomeBlue1Page;