import React from "react";

function FooterGeneral () {
  return (
    <footer id="footer">
      <div className="max-container px-5">
        <section className="f1 mb-4">
          <div className="holder">

            <div className="row">
              <div className="col-12 col-md-6">
                <strong className="logo-footer mb-5">
                  <a href="/">
                    <span className="img">
                      <img alt="etrune logo white" src="/images/logo-footer.svg" width="130" />
                    </span>
                  </a>
                </strong>
                <p className="text-white-50">We design, manufacture, and sell unique engagement rings through co-creative design, augmented reality, the sharing economy, and other technologies.</p>
              </div>

              {/* Company links */}
              <div className="col-lg-3">
                <div className="widget mb-3">
                  <h3 className="mt-0 mb-5">Company</h3>
                  <ul className="list-unstyled links p-0">
                    <li className="my-2"><a href="/company/about" className="text-white-50">About</a></li>
                    <li className="my-2"><a href="/company/contact" className="text-white-50">Contact</a></li>
                    <li className="my-2"><a href="/company/work" className="text-white-50">Work with us</a></li>
                  </ul>
                </div>
              </div>

              {/* Company links */}
              <div className="col-lg-3">
                <div className="widget mb-3">
                  <h3 className="mt-0 mb-5">Legal</h3>
                  <ul className="list-unstyled links p-0">
                    <li className="my-2"><a href="/legal/customer-service" className="text-white-50">Customer service</a></li>
                    <li className="my-2"><a href="/legal/terms" className="text-white-50">Terms & conditions</a></li>
                    <li className="my-2"><a href="/legal/privacy" className="text-white-50">Privacy policy</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="f2 footer-separator">
          <span className="copyright py-5">Copyright <a href="#">ÊTRUNE</a> &copy; {new Date().getFullYear()}. All rights reserved.</span>
        </section>
      </div>
    </footer>
  );
}

export default FooterGeneral;