import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { LayoutGeneral, LayoutMaintenance } from './layouts';
import {
  HomeBlue1Page,
  ContactPage,
  MaintenanceGeneral,
  AboutPage,
  WorkPage,
  CareerPage,
  TermsPage,
  PrivacyPage,
  SuppliersPage,
  CustomerServicePage
} from './pages';

function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutGeneral />}>
          <Route index element={<HomeBlue1Page />} />
          <Route path="company">
            <Route path="about" element={<AboutPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="work" element={<WorkPage />} />
            <Route path="careers" element={<CareerPage />} />
            <Route path="suppliers" element={<SuppliersPage />} />
          </Route>
          <Route path="legal">
            <Route path="customer-service" element={<CustomerServicePage />} />
            <Route path="terms" element={<TermsPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
          </Route>
        </Route>


        <Route path="*" element={<LayoutMaintenance />}>
          <Route path="*" element={<MaintenanceGeneral />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
